body,

.pure-g [class*=pure-u] {
  font-family: 'Helvetica', sans-serif;
    /*color: #D0D0D0;*/
  background: #101010;

}

.pure-u-1-1{
  font-family: 'Helvetica', sans-serif;
  color: #D0D0D0;
  margin: 8px;
  padding: 0px 0px;

}

.header {
  font-family: 'Helvetica', sans-serif;
  color: #D0D0D0;
  margin: 8px;
  padding: 0px 0px;
  text-align: center;
}

.logo {
  font-family: 'Helvetica', sans-serif;
  color: #D0D0D0;
  margin: 0px auto;
  padding: 0px 0px;
  max-width: 1080px;
  border: 8px solid black;
  background-image: url(images/bgd.png);
  text-align: center;
}

.bottom-strip {
  font-family: 'Helvetica', sans-serif;
  font-size: 10px;
  color: #D0D0D0;
  margin: 0px auto;
  padding: 0px 0px;
  max-width: 1080px;
  border: 8px solid black;
  background-image: url(images/bgd.png);
  text-align: center;
}

 .buttons-bar {
   font-family: 'Helvetica', sans-serif;
   color: #FFFFFF;
   margin: -8px auto;
   padding: 0px 0px;
   max-width: 1080px;
   border: 8px solid black;
   background: #e03997;
}

.pagination {
  font-family: 'Helvetica', sans-serif;
  margin: -8px auto;
  padding: 0px 0px;
  max-width: 1080px;
  border: 8px solid black;
  background: #505050;
}

.tabs {
  font-family: 'Helvetica', sans-serif;
  color: #000000;
  margin: 0px;
  padding: 0px 0px;
}

.video {
  margin: 0px auto;
  padding: 0px 0px;
  max-width: 1080px;
  border: 8px solid black;
  background: #101010;
}

.carousel {
  margin: 0px auto;
  padding: 0px 0px;
  max-width: 1080px;
  border: 4px solid black;
  background: #101010;
}

.carousel2 {
  margin: 0px auto;
  padding: 0px 0px;
  max-width: 1080px;
  border: 0px solid black;
  background: #101010;
}

.carousel3 {
  margin: -80px auto;
  padding: 0px 0px;

  border: 0px solid black;

}

.iframe-container {
   margin: 0px auto;
   padding: 0px 0px;
   max-width: 1080px;
   border: 8px solid black;
   background: #101010;

}

.h_link {
  font-family: 'Helvetica', sans-serif;
  color: #404040;
  margin: 0px;
  padding: 0px 0px;
  text-align: left;
}

.sold {
  font-family: 'Helvetica', sans-serif;
  font-size: 30px;
  color: #ec008c;
  margin: 8px;
  padding: 0px 0px;
  text-align: center;
}

h1, h2, h3 {
  font-family: 'Helvetica', sans-serif;
  color: #000000;
  margin: 8px;
  padding: 0px 0px;
}

li {
  font-family: 'Helvetica', sans-serif;
  color: #D0D0D0;
  margin: 10px;
  padding: 0px 0px;
}

code {
  padding: .25rem;
  margin: 0 .25rem;
  background: #eee;
}

.token_interface {
  font-family: 'Helvetica', sans-serif;
  color: #000000;
  margin: -8px auto;
  padding: 0px 10px;
  max-width: 1080px;
  border: 8px solid black;
  background: #D0D0D0;
}

.paypal_button {
  font-family: 'Helvetica', sans-serif;
  margin: -8px auto;
  max-width: 1080px;
  border: 8px solid black;
  background-image: url(images/bgd.png);
}

.paypal {
  font-family: 'Helvetica', sans-serif;
  margin: -8px auto;
  padding: 0px 10px;
  max-width: 1080px;
  border: 8px solid black;
  background: #D0D0D0;
}

.token_owners_accordion {
  font-family: 'Helvetica', sans-serif;
  background-image: url(images/bgd.png);
}

.table {
  font-family: 'Helvetica', sans-serif;
  /*font-size: 10px;*/

  color: #D0D0D0;
  margin: -8px auto;
  padding: 0px 0px;
  max-width: 1080px;
  border: 8px solid black;
  background-image: url(images/bgd.png);
}

.token_admin {
  font-family: 'Helvetica', sans-serif;
  /*font-size: 10px;*/
  color: #D0D0D0;
  margin: -8px auto;
  padding: 10px 10px;
  max-width: 1080px;
  border: 8px solid black;
  background-image: url(images/bgd.png);
}

.contract-hash {
  font-family: 'Helvetica', sans-serif;
  font-size: 10px;
  color: #D0D0D0;
  margin: -8px auto;
  padding: 10px 0px;
}

.hash{
    -ms-word-break: break-all;
    word-break: break-all;

 /* Non standard for webkit */
     word-break: break-word;

    -webkit-hyphens: auto;
       -moz-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}

.agreement_button {
  font-family: 'Helvetica', sans-serif;
  margin: -8px;
  padding: 0px 0px;
}

.buy_button {
  font-family: 'Helvetica', sans-serif;
  margin: -8px;
  padding: 0px 0px;
}

.container {
  font-family: 'Helvetica', sans-serif;
  color: #D0D0D0;
  box-sizing: border-box;
  width: 100%;
  padding: 0px 0px;
  margin: 0px auto;
  background: #101010;
}

.pure-button-primary {
  background-color: #0c1a2b;
}

.pure-button-primary:hover {
  background-color: #233e5e;
}

.pure-form input[type="text"]:focus {
  border-color: #0c1a2b;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

ul li:not(:last-child) {
  margin-bottom: 15px;
}

/* NAVBAR */

.navbar {
  position: fixed;
  padding: 5px;
  background: #0c1a2b;
  font-family: 'Oswald', 'Arial Narrow', sans-serif;
}

.navbar a {
  color: #fff;
}

.navbar a:active,
.navbar a:focus,
.navbar a:hover {
  background: #233e5e;
}

.navbar .pure-menu-heading {
  font-weight: bold;
  text-transform: none;
}

.navbar .navbar-right {
  float: right;
}

.navbar .uport-logo {
  height: 16px;
  margin-right: 10px;
}

/* LOADING SCREEN */

.loading-screen {
  opacity: 1;
  visibility: visible;
  transition: all .25s ease-in-out;

}

.loading-screen.loaded {
  opacity: 0;
  visibility: hidden;
}

/* FORMS */

.pure-form {
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  color: #000000;
}

/* ALERTS */

.alert {
  padding: .5rem;
  color: darkgreen;
  background: darkseagreen;
  border: 1px solid darkgreen;
}

.App {
  text-align: center;
  margin: 2rem;
}

.section {
  text-align: left;
  max-width: 720px;
  margin: 4rem auto auto;
}
